import React from 'react';
import { useParallax } from 'react-scroll-parallax';
import style from './parallax-group.module.scss';
import cx from 'classnames';
import { useBreakpoint } from '../util/use-breakpoint.hook';

interface ParallaxGroupProps {
    speed?: number;
    children?: React.ReactNode;
    type: 'work' | 'about' | 'contact';
}

export const ParallaxGroup: React.FC<ParallaxGroupProps> = ({
    speed = 0,
    children,
    type,
    ...props
}) => {
    const { ref } = useParallax<HTMLDivElement>({ speed });
    const isMobile = useBreakpoint();
    return (
        <div
            className={cx(style.wrapper, style[type])}
            ref={ref}
            {...props}
            id={type}
            style={{
                height: isMobile
                    ? 'auto'
                    : `${window.document.documentElement.clientHeight}px`,
            }}
        >
            {children}
        </div>
    );
};
