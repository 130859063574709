import { useCallback, useEffect, useState } from 'react';
import { CloseIcon } from '../icons/close-icon.component';
import { HamburgerIcon } from '../icons/hamburger-icon.component';
import { WebLinks } from '../web-links/web-links.component';
import style from './menu.module.scss';
import cx from 'classnames';

export const Menu: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    const MenuComponent = isVisible ? CloseIcon : HamburgerIcon;

    const onMenuIconClick = useCallback(() => {
        setIsVisible(!isVisible);
    }, [isVisible]);

    const onLinksClick = useCallback(() => {
        setIsVisible(false);
    }, []);

    useEffect(() => {
        document.body.style.overflow = isVisible ? 'hidden' : 'auto';
    }, [isVisible]);

    return (
        <>
            <div
                onClick={onMenuIconClick}
                className={cx(style.menuIcon, { [style.visible]: isVisible })}
            >
                <MenuComponent />
            </div>
            {isVisible && (
                <div
                    className={style.menuContainer}
                    onClick={onLinksClick}
                    style={{
                        height: `${window.document.documentElement.clientHeight}px`,
                    }}
                >
                    <div className={style.linksContainer}>
                        <WebLinks orientation='column' />
                    </div>
                </div>
            )}
        </>
    );
};
