import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Layout } from '../components/layout/layout.component';
import { Link } from '../components/link/link.component';
import style from './about.module.scss';

const Carousel = lazy(
    () => import('../components/carousel/carousel.component'),
);

export const About: React.FC = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = useCallback(() => {
        if (window.pageYOffset > 80) {
            setIsScrolled(true);
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <Layout title='About' orientation='right'>
            <div className={style.container}>
                <p className={style.description}>
                    Starting 2015 I am building web applications from small to
                    large scale using the most popular Javascript libraries and
                    frameworks, with an eye for detail, a confident and
                    proactive work style that allows me to learn quickly and
                    adapt to any requirements.
                    <br />
                    <br />
                    Outside of working hours I like to read biographies and
                    books that can make me learn something new, tweak{' '}
                    <Link
                        type='about'
                        href='https://discord.bots.gg/bots/859410202208763934'
                        target='_blank'
                    >
                        GridMaker
                    </Link>
                    , one of the Discord bots that I manage, watch football
                    games of my favourite English Premier League team, or hike
                    while I try to capture a mountain scene with my camera so
                    that later I can spend countless hours editing and adding
                    them to my{' '}
                    <Link
                        type='about'
                        href='https://500px.com/p/andrei_macavei'
                        target='_blank'
                    >
                        500px
                    </Link>{' '}
                    profile.
                    <br />
                    <br />
                    In the winter season I will be on some forest slope,
                    enjoying the sound of carving with my snowboard.
                </p>
                {isScrolled && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Carousel
                            images={[
                                'aletsch.webp',
                                'autumn.webp',
                                'black-white.webp',
                                'blue-lake-zurich.webp',
                                'reflection.webp',
                                'ireland.webp',
                                'matterhorn.webp',
                                'matterhorn-sunset.webp',
                            ]}
                        />
                    </Suspense>
                )}
            </div>
        </Layout>
    );
};
