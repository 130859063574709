import { useBreakpoint } from '../../util/use-breakpoint.hook';
import { Content } from './content/content.component';
import { Description } from './description/description.component';
import style from './layout.module.scss';

interface LayoutProps {
    title: string;
    hasSocial?: boolean;
    children?: React.ReactNode;
    orientation?: 'left' | 'right';
}

export const Layout: React.FC<LayoutProps> = ({
    title,
    hasSocial = false,
    orientation = 'right',
    children,
}) => {
    const isMobile = useBreakpoint();
    return (
        <div className={style.wrapper}>
            {orientation === 'right' && !isMobile && (
                <Content>{children}</Content>
            )}
            <Description title={title} hasSocial={hasSocial} />
            {(orientation === 'left' || isMobile) && (
                <Content>{children}</Content>
            )}
        </div>
    );
};
