import style from './link.module.scss';
import cx from 'classnames';

export interface LinkProps
    extends React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    > {
    children?: React.ReactNode;
    type?: 'default' | 'about' | 'work';
}

export const Link: React.FC<LinkProps> = ({ children, type, ...props }) => {
    return (
        <a
            className={cx(
                style.link,
                type ? style[type] : undefined,
                props.className,
            )}
            {...props}
        >
            {children}
        </a>
    );
};
