import { ChevronDownIcon } from '../../components/icons/chevron-down-icon.component';
import style from './scroll-arrow.module.scss';

export const ScrollArrow: React.FC = () => {
    return (
        <div className={style.container}>
            <ChevronDownIcon />
        </div>
    );
};
