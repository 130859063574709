import { Link } from './../link/link.component';
import cx from 'classnames';
import style from './web-links.module.scss';

interface WebLinksProps {
    orientation?: 'row' | 'column';
}

export const WebLinks: React.FC<WebLinksProps> = ({ orientation = 'row' }) => {
    const links = [
        {
            label: 'Work',
            href: '#work',
        },
        {
            label: 'About',
            href: '#about',
        },
        {
            label: 'Contact',
            // eslint-disable-next-line no-script-url
            href: 'javascript: window.scrollTo(0, document.body.scrollHeight);',
        },
    ];

    return (
        <div className={cx(style.wrapper, style[orientation])}>
            {links.map((link, index) => (
                <Link key={link.label + index} href={link.href} type='default'>
                    {link.label}
                </Link>
            ))}
        </div>
    );
};
