import { Avatar } from '../components/avatar/avatar.component';
import { Header } from './header/header.component';
import { Headline } from './headline/headline.component';
import { ScrollArrow } from './scroll-arrow/scroll-arrow.component';
import style from './home.module.scss';
import { useBreakpoint } from '../util/use-breakpoint.hook';

export const Home: React.FC = () => {
    const isMobile = useBreakpoint();

    return (
        <div className={style.container}>
            {!isMobile && <Header />}
            <div
                className={style.wrapper}
                style={{
                    height: `${
                        window.document.documentElement.clientHeight - 214
                    }px`,
                }}
            >
                <div className={style.textContainer}>
                    <Headline />
                    <p className={style.description}>
                        Fast-learner and proactive front-end developer.
                        Certified Scrum Master.
                    </p>
                </div>
                <div className={style.avatarContainer}>
                    <Avatar size={isMobile ? 200 : 300} />
                </div>
            </div>
            <ScrollArrow />
        </div>
    );
};
