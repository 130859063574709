import { Link } from '../link/link.component';
import style from './social.module.scss';

type SocialType = 'discord' | 'instagram' | 'linkedin' | 'github';

const LinkMap: Record<SocialType, string> = {
    discord: 'https://discordapp.com/users/228199797671067648',
    instagram: 'https://www.instagram.com/a.macavei.photo/',
    linkedin: 'https://www.linkedin.com/in/andrei-macavei',
    github: 'https://github.com/Azhmo',
};

export const Social: React.FC = () => {
    const socials: SocialType[] = ['discord', 'github', 'linkedin'];
    return (
        <div className={style.wrapper}>
            {socials.map((social, index) => (
                <Link
                    key={social + index}
                    href={LinkMap[social]}
                    target='_blank'
                >
                    <img
                        alt={`${social}`}
                        src={`/${social}.svg`}
                        width={40}
                        height={40}
                    />
                </Link>
            ))}
        </div>
    );
};
