import style from './avatar.module.scss';

interface AvatarProps {
    size?: number;
}

export const Avatar: React.FC<AvatarProps> = ({ size = 300 }) => {
    return (
        <img
            className={style.avatar}
            alt='avatar'
            height={size}
            width={size}
            src={'/avatar_500.webp'}
        />
    );
};
