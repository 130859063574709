import React, { useEffect, useState } from 'react';
import style from './headline.module.scss';
import cx from 'classnames';

export const Headline: React.FC = () => {
    const [textToDisplay, setTextToDisplay] = useState('');
    const [index, setIndex] = useState(0);
    const text = `Hi, I'm Andrei. `;

    useEffect(() => {
        if (index < text.length) {
            setTimeout(
                () => {
                    setTextToDisplay(textToDisplay + text[index]);
                    setIndex(index + 1);
                },
                index === 0 ? 3000 : index === 3 || index === 4 ? 500 : 100,
            );
        }
    }, [index]);

    return (
        <h1 className={style.title}>
            <span>{textToDisplay}</span>
            {textToDisplay.length < text.length && (
                <span
                    className={cx(style.cursor, {
                        [style.hasAnimation]:
                            textToDisplay.length === 0 ||
                            textToDisplay.length === 4,
                    })}
                >
                    _
                </span>
            )}
        </h1>
    );
};
