import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import { About } from './about/about.component';
import { Menu } from './components/menu/menu.component';
import { Home } from './home/home.component';
import { ParallaxGroup } from './parallax-group/parallax-group.component';
import { useBreakpoint } from './util/use-breakpoint.hook';
import { Suspense, useCallback, lazy, useEffect, useState } from 'react';
const Work = lazy(() => import('./work/work.component'));
const Contact = lazy(() => import('./contact/contact.component'));

function App() {
    const isMobile = useBreakpoint();
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = useCallback(() => {
        if (window.pageYOffset > 0) {
            setIsScrolled(true);
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <>
            <Menu />
            <ParallaxProvider>
                <ParallaxBanner
                    layers={[
                        {
                            image: '/home_background.webp',
                            speed: isMobile ? 0 : -50,
                            expanded: false,
                        },
                        {
                            children: <Home />,
                            speed: isMobile ? 0 : -35,
                            expanded: false,
                        },
                    ]}
                    style={{
                        height: `${window.document.documentElement.clientHeight}px`,
                        overflow: 'auto !important',
                        zIndex: isMobile ? 'unset' : 2,
                        position: 'relative',
                    }}
                />
                <ParallaxGroup type='about'>
                    <About />
                </ParallaxGroup>
                {isScrolled && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ParallaxGroup type='work'>
                            <Work />
                        </ParallaxGroup>
                        <ParallaxGroup
                            speed={isMobile ? 0 : -50}
                            type='contact'
                        >
                            <Contact />
                        </ParallaxGroup>
                    </Suspense>
                )}
            </ParallaxProvider>
        </>
    );
}

export default App;
