import { Social } from "../../social/social.component";
import { WebLinks } from "../../web-links/web-links.component";
import style from "./description.module.scss";

interface DescriptionProps {
  title: string;
  hasSocial?: boolean;
}

export const Description: React.FC<DescriptionProps> = ({
  title,
  hasSocial = false,
}) => {
  return (
    <div className={style.wrapper}>
      <p className={style.title}>
        {title}
        {hasSocial && <Social />}
      </p>
      <div className={style.webLinksContainer}>
        <WebLinks />
      </div>
    </div>
  );
};
